const cards = [
  {
    id: 1,
    title: 'Loss',
    description: "Often associated with feelings of yearning, sorrow or emptiness. The loss card often has negative connotations however when paired with others can be a marker to move on from."
  },
  {
    id: 2,
    title: 'Love',
    description: "The love card has less layered symbolism than many others in Tarot. However all is not as it seems, the card as well as signifying the arrival of love can also mean desire, fulfillment and passion outside of human connection."
  },
  {
    id: 3,
    title: 'Life',
    description: "This card stretches across the planes of being however rather than focussing on yourself it reflects more a sense of achievement, time or endurance."
  },
  {
    id: 4,
    title: 'Tower',
    description: "The tower is a negative card denoting unexpected events and changes. These could be driven by some sort of accident, disaster or damage. Creating catastrophic or disastrous events."
  },
  {
    id: 5,
    title: 'Afterlife',
    description: "Don’t worry. The end isn’t nigh. Instead this allows you to look ahead. Think of this card more as your astrological telescope, bringing you news of the future and rewards that lie on the horizon."
  },
  {
    id: 6,
    title: 'Marriage',
    description: "This card doesn’t refer to the holy vows. Instead it has layered meaning, on one side potentially a sense of duality or joining. It can also mean commitment or sacrifice either to a person or an endeavour."
  },
  {
    id: 7,
    title: 'Spectre',
    description: "There are many things that can haunt someone, a thought, a person. The spectre refers to elements of your past that trouble you, things that you might find it hard to move on from. The spectre can also be a release from these things when paired with other cards. Helping you to finally move on."
  },
  {
    id: 8,
    title: 'Oracle',
    description: "Although aligned to the afterlife in its purpose the oracle helps you see things that aren’t yet in your future but have the potential to be. It is a visionary card, helping to see beyond the physical and share an alternate future that can be."
  },
  {
    id: 9,
    title: 'Mischief',
    description: "The mistress of mischief is not to be underestimated. Mischief can cause havoc across all areas of your life and signifies change, struggle or danger. So watch those horizons for anything nasty coming your way!"
  },
  {
    id: 10,
    title: 'Writer',
    description: "The writer is both diligent and thoughtful, a planner. It can mean that more consideration is needed or signify that a plan you’d put in place may need reviewing. To fail to prepare is to prepare to fail."
  },
  {
    id: 11,
    title: 'Triptych',
    description: "The triptych, a series of three. This can be interpreted in a number of ways, each element playing an individual part or seen as one. Things that seem far apart from one another may be more intertwined than first thought."
  },
  {
    id: 12,
    title: 'Children',
    description: "Children is symbolic of innocence, playfulness and joy. However in certain situations can also be interpreted as chaos. It can refer to people or actions and so think carefully what and who is bringing you joy or causing you stress."
  },
  {
    id: 13,
    title: 'Moon',
    description: "The moon represents the unsaid, elements of illusion. It touches on your fears, anxieties and intuition. It may suggest that you look twice at those around you as things are not always as they seem."
  }
]

const storiesCareer = [
  {
    cards: [cards[7], cards[9], cards[5]],
    story: "The Fates decree that your career must rise from the doldrums! Somewhat counterintuitively, you must stick at it in order to see the change you so desire. The stars are very clear about this."
  },
  {
    cards: [cards[12], cards[8], cards[6]],
    story: "One of your colleagues has been the cause of frustration for you. Do not act rashly, but instead bide your time for the right moment to engage your workmate in a frank and constructive exchange of ideas, or simply undermine them in a meeting with some choice words of disparagement."
  },
  {
    cards: [cards[1], cards[4], cards[0]],
    story: "You have worked your fingers to the bone for too long. Beg, bargain, or bribe your superiors for a fanciful sabbatical in the sun. Return to your job, if you absolutely must continue to work, with your ideas and your tan refreshed."
  },
  {
    cards: [cards[8], cards[11], cards[9]],
    story: "It’s never too late to make a change. Become acquainted with your inner magnate, and turn one of your many far-fetched ideas into an unlikely business venture. The current alignment of Orion, one of the galaxy’s sillier stars, supports this."
  },
  {
    cards: [cards[3], cards[2], cards[10]],
    story: "You find yourself at a crossroads: what route should you take? Obviously not the route by which you arrived at the crossroads, that much is certain. That would involve going backwards. Pick any of the two or three remaining paths - preferably one that goes forwards - and stick to it resolutely.",
  }
];

const storiesLove = [
  {
    cards: [cards[10], cards[12], cards[6]],
    story: "Things always happen in threes. The fates seem to have a triple dose of doom in store for you, guaranteed to wreak havoc on your day-to-day and have you spiraling into an existential crisis. But fear not - once you’ve hit rock bottom, you have nowhere to go but up!"
  },
  {
    cards: [cards[8], cards[2], cards[11]],
    story: "Communication is of the essence, but in the case of your relationship the lines are down. Return some playfulness to your rapport by engaging your partner in some spirited parlour games, to establish which one of you has the upper hand."
  },
  {
    cards: [cards[1], cards[12], cards[7]],
    story: "Cast your net far and wide when seeking a new mate. The right person for you will turn up eventually - this is as much scientific fact as it is horoscopy. The stars and the facts do not often align, so you can be confident about this one."
  },
  {
    cards: [cards[6], cards[11], cards[0]],
    story: "The ghosts of liaisons past, have been haunting you. It is perfectly reasonable to want to run from these phantoms! The chaos and panic you will experience while fleeing the spirits of past failures will prove exceptionally invigorating. Scream at the top of your lungs as you speed away!"
  },
  {
    cards: [cards[3], cards[4], cards[2]],
    story: "Do not grieve for a defunct amour that never stood a chance. Bury it with a rousing funeral, and see it off with a truly eye-watering wake. A cocktail shaker is your friend in these times."
  },
  {
    cards: [cards[10], cards[8], cards[1]],
    story: "Don't let the hurt of your past impact the fun of your future. Jump in to something or someone new. Better yet, jump into a few people. The more the merrier and maybe three is your magic number! Loosen up and let life come to you."
  },
  {
    cards: [cards[12], cards[5], cards[1]],
    story: "Develop a new bond with somebody unlikely. The moon’s shadow is long tonight, presaging a strange and beautiful friendship lasting between six hours and six decades. Take heart: if this friendship feels wrong, it will be easier to quit than your spouse."
  }
];

const storiesWealth = [
  {
    cards: [cards[7], cards[4], cards[8]],
    story: "The fates conspire to make you pass a wishing well while taking a walk this week. Wear some firm rubber boots with which to wade out into the water and collect other people’s coins."
  },
  {
    cards: [cards[3], cards[7], cards[5]],
    story: "Vast riches will tumble into your lap this week as a result of something you did a long time ago. Perhaps you devised a mathematical equation while doodling, or placed a bet on someone’s death. At any rate, your newfound wealth will come as a great surprise and force you to reconnect with many long-lost cousins."
  },
  {
    cards: [cards[9], cards[7], cards[11]],
    story: "Shazaam! You're rich! Sadly that's not quite how it works - but it doesn't mean that the lifestyle you crave is out of reach. Lighten up and find what you enjoy, stop doing things that make you sad and you might see more success. Remember: success isn't always measured by the size of your bank balance."
  },
  {
    cards: [cards[12], cards[6], cards[2]],
    story: "You have been painting the town red, spending coin all over town. It is time for you to stay home and paint the nook in the living-room: this will save on money, and the consummate boredom will make the rest of your life seem like a blast."
  },
  {
    cards: [cards[1], cards[9], cards[11]],
    story: "There is no rhyme or reason to money. Turn your back on the very concept of cash, and set up your own private bartering system. Salespeople and your spouse are sure to be delighted by this new approach, and you may find that you enjoy being paid in root vegetables."
  },
  {
    cards: [cards[3], cards[7], cards[2]],
    story: "You will believe in miracles this week - but, my dear, nothing is impossible when Mercury is on the wane! A big surprise could be in store for your fortune, but I cannot reveal what that will be. You must trust in the power of kismet, and the ludicrous will surely come true."
  }
];

export {storiesLove, storiesWealth, storiesCareer};
