import React, { Component } from 'react'
import style from './category.module.scss'

class Category extends Component {
  render() {
    return (
      <div className={style.card + " " + style[this.props.category]}></div>
    )
  }
}

export default Category
